import React, {useEffect} from 'react';
import { Redirect, Route, Switch, useLocation  } from 'react-router-dom';
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import Organizations from '../Organizations';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import EverpassTopBar from '../../components/EverpassTopBar';
import EverpassSideBar from '../../components/EverpassSideBar';
import GlobalDialog from '../../components/GlobalDialog';
import RedirectSpotlight from './Redirect/RedirectSpotlight';
import RedirectEntertainment from './Redirect/RedirectEntertainment';
import { client } from '../../api/axios';
import UsernameWarning from '../../components/UsernameWarning';

import './Home.scss';
import FlagWave from '../../assets/Flag_Wave.webm';

function extractLastOrganizationIdFromPath(path) {
    const match = path.match(/\/organizations\/(\d+(?:-\d+)*)\//);
    if (match) {
      const ids = match[1].split('-').map(Number);
      return ids[ids.length - 1];
    }
    return null; // Return null if no match is found
}

const ORGANIZATION_THEME = gql`
    query OrganizationTheme($orgId: Int!) {
        Organization(id: $orgId){
            id
            has_feature(feature: "everpass_manager")
        }
    }
`;

const HomeThemeSelector = ({ setTheme }) => {
    const location = useLocation();
    const currentOrgId = extractLastOrganizationIdFromPath(location.pathname);

    const { data, loading } = useQuery(ORGANIZATION_THEME, {
        fetchPolicy: 'cache-and-network',
        variables: { orgId: currentOrgId },
        skip: !currentOrgId
    });

    const isEverpassManager = data?.Organization.has_feature ?? false;
    
    useEffect(() => {
        if (isEverpassManager) {
            document.body.classList.add('everpass');
            document.body.style.overflow = 'hidden';
            setTheme('everpass');
          } else {
            document.body.classList.remove('everpass');
            document.body.style.overflow = '';
            setTheme('classic');
          }

          return () => {
            document.body.classList.remove('everpass');
            document.body.style.overflow = '';
          };
    }, [isEverpassManager]);

    return <>
        { loading && <div className='loading-page'> 
            <video 
                src={FlagWave} 
                autoPlay 
                loop 
                muted 
                playsInline
            />
        </div> }
        <HomeSwitcher isEverpassManager={isEverpassManager}/>
    </>
};

const Home = ({ setTheme }) => {
    return (
        <ApolloProvider client={client}>
            <HomeThemeSelector setTheme={setTheme}/>
            <GlobalDialog />
        </ApolloProvider>
    );
};

const HomeSwitcher = ({ isEverpassManager }) => {    
    return (
        <div className="Home">
            { isEverpassManager ? <EverpassTopBar/> : <SideBar/> }
            <div className="page-container">
                { isEverpassManager ? <EverpassSideBar/> : <TopBar/> }
                <div className='main-scroll-container'>
                    <HomeRoutes/>
                </div>
            </div>
        </div>
    );
};

const HomeRoutes = () => {
    return (
        <>
            <UsernameWarning />
            <Switch>
                <Route path="/organizations" component={Organizations} />
                <Route path="/org/:orgId/spt/:sptId" children={<RedirectSpotlight />} />
                <Route path="/games" children={<RedirectEntertainment />} />
                <Route path="/entertainment" children={<RedirectEntertainment />} />
                <Route render={() => <Redirect to="/organizations" />} />
            </Switch>
        </>
    );
};

export default Home;