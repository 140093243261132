import UpBadge from '../../assets/experiences/UpBadge';
import UpShowConnectLogo from '../../assets/experiences/UpShowConnectLogo';
import UpShowHealthLogo from '../../assets/experiences/UpShowHealthLogo';
import EverpassBadge from '../../assets/experiences/EverpassBadge';

const defaultExperience = 'EMPTY_STATE';

const experienceEntries = {
    EMPTY_STATE: {
        key: 'EMPTY_STATE',
        className: 'empty',
        badge: null,
        logo: null
    },
    CONNECT: {
        key: 'CONNECT',
        className: 'connect',
        badge: EverpassBadge,
        logo: UpShowConnectLogo,
    },
    HEALTH: {
        key: 'HEALTH',
        className: 'health',
        badge: UpBadge,
        logo: UpShowHealthLogo
    }
};

const getExperience = (key = defaultExperience) => experienceEntries[key] ?? experienceEntries[defaultExperience];

export { experienceEntries, getExperience };