import { graphqlQuery } from '../axios';
import _get from 'lodash/get';

export async function userInfo () {
    const response = await graphqlQuery({
        query: `query userInfo {
                me {
                    id
                    username
                    email
                    avatar_url
                    bookmarkedOrganizations {
                        id
                        name
                    }
                    organizations {
                        id name leaf root child_tree
                    }
                    settings
                    roles
                }
                availableEventTypes {
                    UFC
                    peacock
                    nfl_sunday_ticket
                    thursday_night_football
                    paramount
                    LIV
                    PowerSlap
                    FUBO
                    STANDARD
                    victory
                }
            }`
    });

    return {
        error: response.data.error,
        response: {
            user: response.data.data.me,
            availableEventTypes: response.data.data.availableEventTypes
        }
    };
}
export function pendoSettings(org_id) {
    return graphqlQuery({
        query: `query pendoUserInfo($org_id: Int) { me { id username email roles pendo_info(org_id: $org_id) } }`,
        variables: { org_id }
    })
    .then(response => {
        const { data } = response;
        const user = data?.data?.me || {};
        let roles = (user?.roles || []).reduce((sum, role) => {
            sum[role] = true;
            return sum
        }, {});
        const pendo_info = user?.pendo_info || {};

        return {
            visitor: {
                id: user.id,
                email: user.email,
                full_name: user.username,
                session_type: "EM Web Session",
                ...roles
            },
            ...pendo_info
        };
    });

}

export function setUserSettings(key, value) {
    return graphqlQuery({
        query: `mutation setUserSettings($key: String!, $value: JSON) {
            userMutation {
                            saveSetting(key: $key value: $value) {
                                successful
                            }
                        }
                }`,
        variables: {
            value,
            key
        }
    })
    .then(response => _get(response, 'data.data.userMutation.saveSetting.successful', false));
}

export function getUserSettings() {
    return graphqlQuery({
        query: `query userSettings{
            me {
                id settings
            }
        }`
    })
    .then(response => _get(response, 'data.data.me.settings', {}));
}

export function bookmarkOrganization(ord_id, isBookmarked) {
    return graphqlQuery({
        query: `mutation bookmarkOrganization($id: Int!, $bookmark: Boolean) {
                    userMutation {
                            bookmarkOrganization(id: $id, bookmark: $bookmark) {
                                     user { id bookmarkedOrganizations {name id } } 
                            }
                        }
                }`,
        variables: {
            id: ord_id,
            bookmark: isBookmarked
        }
    })
    .then(response => _get(response, 'data.data.userMutation.bookmarkOrganization.user.bookmarkedOrganizations', []));
}
