import React, { Suspense } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GET_CAMPAIGNS } from '../../api/queries/campaigns';
import RedirectEverpassHome from '../Home/Redirect/RedirectEverpassHome';

import history from '../../services/HistoryService';
import { parse } from '../../helpers/organizationPath';
import Loading from '../../components/LoadingOverlay/LoadingOverlay';
import FcoCheckMessage from '../../components/FcoCheckMessage';
import redirectV2 from '../Home/Redirect/RedirectV2';
import {
    AssetUploader,
    Audio,
    CampaignCreator,
    CampaignDetails,
    CampaignsInsights,
    CollectionContents,
    CollectionEditor,
    CollectionLibrary,
    CreateSpotlight,
    HomeDashboard,
    InsightsCampaignDetails,
    ListApps,
    ListDevices,
    LiveEventRequest,
    Mixes,
    MixesEditor,
    NewAssetLibrary,
    NewEntertainment,
    PromotionsManager,
    Settings,
    SingleApp,
    SingleSpotlight,
    SocialContent,
    SpotlightApprovals,
    SurveyDetails,
    Surveys,
    DirectShare,
    SmartPlaylist,
    SundayTicket,
    ThursdayNightFootball,
    Paramount,
    Peacock,
    LeaguePage,
    NoFeaturesPage,
} from '../index';

import './Organization.scss';

const RecentCampaignSection = (props) => {
    const { match, currentOrganization } = props;

    const { data, loading } = useQuery(GET_CAMPAIGNS, {
        variables: {
            organizationId: currentOrganization.id,
            limit: 1
        }, fetchPolicy: 'cache-and-network'
    });

    const campaigns = data?.Organization.campaigns ?? [];

    if (loading) {
        return <Loading label="Loading" />;
    }

    const redirectUrl = campaigns.length > 0 ? `campaigns/${campaigns[0].id}` : 'promotions?tab=campaigns';

    return <Redirect to={`${match.url}/${redirectUrl}`} />;
};

class Organization extends React.Component {

    componentDidMount() {
        if (!this.redirectIfRequired() && !this.props.isLoading && !this.props.currentOrganization) {
            this.switchToUrlOrg();
        }
    }

    componentDidUpdate(prevProps) {

        if (!this.redirectIfRequired()) {
            const organizationHasChanged = this.props.match.params.orgPath !== prevProps.match.params.orgPath;

            if (organizationHasChanged) {
                this.switchToUrlOrg();
            }
        }
    }

    redirectIfRequired() {
        const {
            redirectToUrl,
            redirectToRelativeUrlSuccess,
        } = this.props;

        if (redirectToUrl) {
            redirectToRelativeUrlSuccess();
            history.push(redirectToUrl);
            return true;
        }

        return false;
    }

    switchToUrlOrg() {
        const parsedPath = parse(this.props.match.params.orgPath);
        this.props.switchOrganization(parsedPath);
    }

    getFallbackRoute() {
        const { match } = this.props;
        return `${match.url}/home`;
    }

    render() {
        const {
            isSwitchingOrganization,
            errorSwitchingOrganization,
            currentOrganization,
            match,
            isLoading,
            hasPlugins,
            canManageDevices,
            orgHasFeature: orgHas,
            isFetchingUser,
            userHasOrgPermission,
            firstRootOrgId,
            builtPath,
            availableEventTypes
        } = this.props;

        if (errorSwitchingOrganization && !isSwitchingOrganization) {
            return <Redirect to={`/organizations/${firstRootOrgId}/home`} />
        }

        if (builtPath) {
            const pathname = window.location.pathname;
            const pathSegments = pathname.split('/');
            const lastSegment = pathSegments.slice(3).join('/');
            return <Redirect to={`/organizations/${builtPath.join('-')}/${lastSegment}`} />
        }

        if (isSwitchingOrganization || !currentOrganization || isFetchingUser || isLoading) {
            return <div className="Organization">
                <div className="main-container">
                    <div className="page-container">
                        <Loading label={'Loading'} />
                    </div>
                </div>
            </div>;
        }

        const HomeRedirect = orgHas('EVERPASS_MANAGER')
            ? <RedirectEverpassHome match={match} orgHas={orgHas} userHas={userHasOrgPermission} />
            : <HomeDashboard {...this.props} />;

        const newManagerRedirect = (Element, destination) =>
            orgHas('EVERPASS_MANAGER')
            ? redirectV2(currentOrganization.id, destination)
            : Element;

        return (
            <Suspense fallback={<Loading />}>
                <div className="Organization">
                    <div className="main-container">
                        <FcoCheckMessage />
                        <div className="page-container">
                            <Switch>
                                <Route
                                    path={`${match.url}/home`}
                                    render={() => (HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/content/direct-share`}
                                    render={(props) => (orgHas('SOCIAL') ? <DirectShare {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/content`}
                                    render={(props) => (orgHas('SOCIAL') ? <SocialContent {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/spotlights/new`}
                                    render={(props) => (userHasOrgPermission('spotlights') && orgHas('SPOTLIGHT') ? <CreateSpotlight {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/spotlights/:spotlightId`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <SingleSpotlight {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/library/new`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <AssetUploader {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/library`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <NewAssetLibrary {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/collections/new`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <CollectionEditor {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/collections/:collectionId`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <CollectionContents {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/collections`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <CollectionLibrary {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/entertainment/live-events/request/:eventId`}
                                    render={(props) => (userHasOrgPermission('entertainment') && orgHas('ENTERTAINMENT')
                                        ? newManagerRedirect(<LiveEventRequest {...props} />, 'home')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/entertainment`}
                                    render={(props) => (userHasOrgPermission('entertainment') && orgHas('ENTERTAINMENT')
                                        ? newManagerRedirect(<NewEntertainment {...props} />, 'home')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/sunday-ticket`}
                                    render={(props) => (orgHas('LIVE_EVENTS') && availableEventTypes.nfl_sunday_ticket && orgHas('SUNDAY_TICKET')
                                        ? newManagerRedirect(<SundayTicket {...props} />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/peacock`}
                                    render={(props) => (orgHas('LIVE_EVENTS') && availableEventTypes.peacock && orgHas('PEACOCK')
                                        ? newManagerRedirect(<Peacock {...props} />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/nfl`}
                                    render={(props) => (orgHas('LIVE_EVENTS')
                                        ? newManagerRedirect(<LeaguePage {...props} league="nfl" subscriptionType="thursday_night_football" />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/thursday-night-football`}
                                    render={(props) => (orgHas('LIVE_EVENTS') && availableEventTypes.thursday_night_football
                                        ? newManagerRedirect(<ThursdayNightFootball {...props} />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/paramount`}
                                    render={(props) => (orgHas('LIVE_EVENTS') && availableEventTypes.paramount && orgHas('PARAMOUNT')
                                        ? newManagerRedirect(<Paramount {...props} />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/nhl`}
                                    render={(props) => (orgHas('LIVE_EVENTS') && availableEventTypes.victory
                                        ? newManagerRedirect(<LeaguePage {...props} league="nhl" subscriptionType="victory" />, 'live')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/plugins/:connectedAppId`}
                                    render={(props) => (userHasOrgPermission('plugins') && hasPlugins
                                        ? newManagerRedirect(<SingleApp {...props} />, 'home')
                                        : HomeRedirect
                                    )}
                                />
                                <Route
                                    path={`${match.url}/plugins`}
                                    render={(props) => (userHasOrgPermission('plugins') && hasPlugins
                                        ? newManagerRedirect(<ListApps {...props} />, 'home')
                                        : HomeRedirect
                                    )}
                                />
                                {/* Entertaiment routes end */}

                                <Route
                                    path={`${match.url}/devices`}
                                    render={(props) => (userHasOrgPermission('devices') && canManageDevices && orgHas('DEVICES') ? <ListDevices {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/audio`}
                                    render={(props) => (userHasOrgPermission('audio') ? <Audio {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/new_insights`}
                                    render={(props) => (orgHas('SPOTLIGHT') && userHasOrgPermission('insights') ? <CampaignsInsights {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/campaign/:campaignId/insights`}
                                    render={(props) => (orgHas('SPOTLIGHT') ? <InsightsCampaignDetails {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/playlists/new`}
                                    render={(props) => (userHasOrgPermission('playlists') && orgHas('MIXES_EDITOR') ? <MixesEditor {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/playlists/:mixId`}
                                    render={(props) => (userHasOrgPermission('playlists') && orgHas('MIXES_EDITOR') ? <MixesEditor {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/playlists`}
                                    render={(props) => (userHasOrgPermission('playlists') && orgHas('MIXES') ? <Mixes {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/smart_playlists`}
                                    render={(props) => (userHasOrgPermission('playlists') && orgHas('SMART_PLAYLISTS') ? <SmartPlaylist {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/settings`}
                                    render={(props) => ((userHasOrgPermission('org_settings') || userHasOrgPermission('user_management')) ? <Settings {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/campaigns/new`}
                                    render={(props) => (userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? <CampaignCreator {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/campaigns/:campaignId`}
                                    render={(props) => (userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? <CampaignDetails {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/campaigns`}
                                    render={(props) => (userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? <PromotionsManager {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/promotions`}
                                    render={(props) => (userHasOrgPermission('spotlights') && orgHas('SPOTLIGHT') ? <PromotionsManager {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/approvals`}
                                    render={(props) => (orgHas('SPOTLIGHT_APPROVAL') && userHasOrgPermission('spotlight_approver') ? <SpotlightApprovals {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/surveys/:surveyId`}
                                    render={(props) => (userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') ? <SurveyDetails {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/surveys`}
                                    render={(props) => (userHasOrgPermission('campaigns') && orgHas('CAMPAIGNS') && orgHas('SPOTLIGHT') ? <Surveys {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/recent-campaign`}
                                    render={(props) => (orgHas('CAMPAIGNS') ? <RecentCampaignSection {...props} /> : HomeRedirect)}
                                />
                                <Route
                                    path={`${match.url}/no_features`}
                                    render={(props) => (orgHas('EVERPASS_MANAGER') ? <NoFeaturesPage {...props} /> : <HomeDashboard {...props} />)}
                                />
                                <Route
                                    path={`${match.url}`}
                                    render={() => <Redirect to={this.getFallbackRoute()} />}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </Suspense>
        );
    }
}

Organization.propTypes = {
    isSwitchingOrganization: PropTypes.bool.isRequired,
    errorSwitchingOrganization: PropTypes.string,
    currentOrganization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),
    redirectToUrl: PropTypes.string,
    redirectToRelativeUrlSuccess: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasPlugins: PropTypes.bool.isRequired,
    isFetchingUser: PropTypes.bool.isRequired,
    orgHasFeature: PropTypes.func.isRequired,
    userHasOrgPermission: PropTypes.func.isRequired,
    canManageDevices: PropTypes.bool.isRequired,
    switchOrganization: PropTypes.func.isRequired,
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        params: PropTypes.shape({
            orgPath: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    hasRoleCanary: PropTypes.bool.isRequired,
    firstRootOrgId: PropTypes.number.isRequired,
    availableEventTypes: PropTypes.shape({
        nfl_sunday_ticket: PropTypes.bool,
        peacock: PropTypes.bool,
        thursday_night_football: PropTypes.bool,
        paramount: PropTypes.bool,
        victory: PropTypes.bool
    }),
    builtPath: PropTypes.arrayOf(PropTypes.object)
};

export default Organization;
