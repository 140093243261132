import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import Organization from '../../redux/modules/entities/organizations/Organization';

import EverpassSideBar from './EverpassSideBar';

const GET_SPOTLIGHTS_PENDING_REVIEW_COUNT = gql`
    query getSpotlightsPendingReviewCount($organization_id: Int!) {
        Organization(id: $organization_id) {
            id
            spotlightsPendingReviewCount
        }
    }
`;

const mapStateToProps = (state) => {
    const currentOrgId = Organization.getCurrentOrganizationId(state);

    return {
        baseUrl: Organization.getCurrentOrganizationUrl(state),
        hasAccessTypeform: Organization.getTypeformAccess(state, currentOrgId),
        orgHasFeature: Organization.getFeatureAccesor(state),
        userHasOrgPermission: Organization.getPermissionAccesor(state),
        orgId: Organization.getCurrentOrganizationId(state),
    };
};

const mapDispatchToProps = () => ({});

const SideBarContainer = (props) => {
    const { orgId, orgHasFeature } = props;

    const [getSpotlightsPendingReviewCount, { data }] = useLazyQuery(GET_SPOTLIGHTS_PENDING_REVIEW_COUNT, {
        variables: { organization_id: orgId },
        pollInterval: 2 * 60 * 1000,
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (orgId && orgHasFeature('SPOTLIGHT_APPROVAL')) getSpotlightsPendingReviewCount();
    }, [orgId, getSpotlightsPendingReviewCount, orgHasFeature]);

    const spotlightsPendingReview = data?.Organization.spotlightsPendingReviewCount ?? 0;

    return <EverpassSideBar {...props} spotlightsPendingReview={spotlightsPendingReview}/>;

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarContainer));
