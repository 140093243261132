const allFeatures = {
    SOCIAL: 'social',
    PLUTO: 'plutotv',
    UP_NOW: 'upshow_now',
    MEDIA_CHANNEL_PREMIUM: 'jukin',
    MEDIA_CHANNEL_NON_PREMIUM: 'media_channel_non_premium',
    MEDIA_CHANNEL_NHL: 'media_channel_nhl',
    MEDIA_CHANNEL_VOD: 'media_channel_vod',
    SPOTLIGHT: 'spotlights',
    ANALYTICS: 'analytics',
    DEVICES: 'device_manager',
    GAMES: 'upshow_games',
    TOUCHTUNES: 'touchtunes',
    MIXES: 'upshow_mixes',
    CREATE_SCHEDULES_MIXES: 'upshow_mixes_create_schedules',
    MIXES_SET_DEFAULT: 'upshow_mixes_set_default',
    MIXES_SET_LOCKING: 'upshow_mixes_set_locking',
    MIXES_EDITOR: 'upshow_mixes_editor',
    GOOGLE_ADMIN_DATA: 'google_admin_data',
    MIXES_GRANULARITY: 'mixes_granularity',
    SPOTLIGHT_TAGS: 'spotlight_tags',
    DISPLAY_RULES: 'display_rules',
    SPOTLIGHT_BLAZIN_BY_STORE: 'spotlight_blazin_by_store',
    LIVE_EVENTS: 'live_events',
    SPORTSBOOK: 'sportsbook',
    SPORTSBOOK_SCHEDULE: 'sportsbook_schedule',
    CAMPAIGNS: 'campaigns',
    CREATE_FROM_SIDEBAR: 'create_from_sidebar',
    OWN_CANVA_LOGIN: 'own_canva_login',
    CAMPAIGN_CANVAS_DEFAULT_CREATOR: 'campaign_canvas_default_creator',
    SPOTLIGHT_APPROVAL: 'spotlight_approval',
    PREMIUM_MUSIC: 'premium_music',
    MULTIPLE_DESTINATIONS_CAMPAIGN: 'multiple_destinations_campaign',
    BILLING: 'billing',
    AUDIO_ANNOUNCEMENT: 'audio_announcement',
    ORGANIZATION_LABELS: 'organization_labels',
    SPOTLIGHT_LAYOUT_MULTI: 'multiple_graphic_spotlight',
    DR_TIME_BASED: 'dr_time_based',
    SMART_PLAYLISTS: 'smart_playlists',
    PEACOCK: 'peacock',
    BLOCK_CONTENT: 'block_content',
    SUNDAY_TICKET: 'sunday_ticket',
    LIVE_EVENTS_MULTIPLE_DEVICES:'live_events_multiple_devices',
    EVERPASS_MANAGER: 'everpass_manager',
    PARAMOUNT: 'paramount'
};

const functionalitySelector = {
    SOCIAL: [allFeatures.SOCIAL],
    PLUTO: [allFeatures.PLUTO],
    UP_NOW: [allFeatures.UP_NOW],
    MEDIA_CHANNEL_PREMIUM: [allFeatures.MEDIA_CHANNEL_PREMIUM],
    MEDIA_CHANNEL_NON_PREMIUM: [allFeatures.MEDIA_CHANNEL_NON_PREMIUM],
    MEDIA_CHANNEL_NHL: [allFeatures.MEDIA_CHANNEL_NHL],
    MEDIA_CHANNEL_VOD: [allFeatures.MEDIA_CHANNEL_VOD],
    MEDIA_CHANNEL: [allFeatures.MEDIA_CHANNEL_PREMIUM, allFeatures.MEDIA_CHANNEL_NON_PREMIUM, allFeatures.MEDIA_CHANNEL_NHL, allFeatures.MEDIA_CHANNEL_VOD],
    ENTERTAINMENT: [allFeatures.PLUTO, allFeatures.UP_NOW, allFeatures.MEDIA_CHANNEL_PREMIUM, allFeatures.GAMES, allFeatures.MEDIA_CHANNEL_NON_PREMIUM, allFeatures.MEDIA_CHANNEL_NHL, allFeatures.MEDIA_CHANNEL_VOD, allFeatures.LIVE_EVENTS, allFeatures.SPORTSBOOK],
    ANALYTICS: [allFeatures.ANALYTICS],
    SPOTLIGHT: [allFeatures.SPOTLIGHT],
    DEVICES: [allFeatures.DEVICES],
    GAMES: [allFeatures.GAMES],
    TOUCHTUNES: [allFeatures.TOUCHTUNES],
    MIXES: [allFeatures.MIXES, allFeatures.MIXES_EDITOR],
    CREATE_SCHEDULES_MIXES: [allFeatures.CREATE_SCHEDULES_MIXES],
    MIXES_SET_DEFAULT: [allFeatures.MIXES_SET_DEFAULT],
    MIXES_SET_LOCKING: [allFeatures.MIXES_SET_LOCKING],
    MIXES_EDITOR: [allFeatures.MIXES_EDITOR],
    GOOGLE_ADMIN_DATA: [allFeatures.GOOGLE_ADMIN_DATA],
    MIXES_GRANULARITY: [allFeatures.MIXES_GRANULARITY],
    SPOTLIGHT_TAGS: [allFeatures.SPOTLIGHT_TAGS],
    DISPLAY_RULES: [allFeatures.DISPLAY_RULES],
    LIVE_EVENTS: [allFeatures.LIVE_EVENTS],
    SPOTLIGHT_BLAZIN_BY_STORE: [allFeatures.SPOTLIGHT_BLAZIN_BY_STORE],
    SPORTSBOOK: [allFeatures.SPORTSBOOK],
    SPORTSBOOK_SCHEDULE: [allFeatures.SPORTSBOOK_SCHEDULE],
    CAMPAIGNS: [allFeatures.CAMPAIGNS],
    CREATE_FROM_SIDEBAR: [allFeatures.CREATE_FROM_SIDEBAR],
    CAMPAIGN_CANVAS_DEFAULT_CREATOR: [allFeatures.CAMPAIGN_CANVAS_DEFAULT_CREATOR],
    OWN_CANVA_LOGIN: [allFeatures.OWN_CANVA_LOGIN],
    SPOTLIGHT_APPROVAL: [allFeatures.SPOTLIGHT_APPROVAL],
    PREMIUM_MUSIC: [allFeatures.PREMIUM_MUSIC],
    MULTIPLE_DESTINATIONS_CAMPAIGN: [allFeatures.MULTIPLE_DESTINATIONS_CAMPAIGN],
    BILLING: [allFeatures.BILLING],
    AUDIO_ANNOUNCEMENT: [allFeatures.AUDIO_ANNOUNCEMENT],
    ORGANIZATION_LABELS: [allFeatures.ORGANIZATION_LABELS],
    SPOTLIGHT_LAYOUT_MULTI: [allFeatures.SPOTLIGHT_LAYOUT_MULTI],
    DR_TIME_BASED: [allFeatures.DR_TIME_BASED],
    SMART_PLAYLISTS: [allFeatures.SMART_PLAYLISTS],
    PEACOCK: [allFeatures.PEACOCK],
    BLOCK_CONTENT: [allFeatures.BLOCK_CONTENT],
    SUNDAY_TICKET: [allFeatures.SUNDAY_TICKET],
    LIVE_EVENTS_MULTIPLE_DEVICES: [allFeatures.LIVE_EVENTS_MULTIPLE_DEVICES],
    EVERPASS_MANAGER: [allFeatures.EVERPASS_MANAGER],
    PARAMOUNT: [allFeatures.PARAMOUNT]
};

const hasFeature = (feature, enabled_features = []) => {
    return enabled_features.some(enabled_feature => functionalitySelector[feature]?.some(featureNameToCheck => enabled_feature === featureNameToCheck));
};

export const buildFeatureAccesor = (enabled_features = []) => {
    return (feature) => hasFeature(feature, enabled_features);
};
