import { useRef, useEffect, useState } from "react";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";

import "./TopNavigationSearch.scss";

const TopNavigationSearch = ({ onSearch }) => {
    const inputRef = useRef(null);
    const containerRef = useRef(null);

    const [searching, setSearching] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const removeSearchTerm = (e) => {
        e.stopPropagation();
        if (inputRef.current) inputRef.current.focus();
        setInputValue("");
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (!inputValue) {
            setSearching(false);
            return;
        }

        onSearch(inputValue);
    };

    useEffect(() => {
        const handleClick = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setSearching(false);
            }
        };

        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, []);

    return (
        <form onSubmit={handleSearchSubmit} className="search-form">
            <div ref={containerRef} className={`search-container ${searching ? "open" : ""}`}>
                <button type="button" className="search-icon" onClick={() => setSearching((s) => !s)}>
                    <SearchOutlined />
                </button>

                {searching && 
                    <input 
                        ref={inputRef} 
                        value={inputValue} 
                        onChange={(e) => setInputValue(e.target.value)} 
                        placeholder="Search events, teams, channels" 
                        autoFocus={searching} 
                        className="search-input" 
                    />
                }

                {searching && inputValue && (
                    <button type="button" onClick={removeSearchTerm} className="search-icon">
                        <CloseIcon />
                    </button>
                )}
            </div>
        </form>
    );
};

export default TopNavigationSearch;
