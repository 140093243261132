import { Redirect } from 'react-router-dom';

const RedirectEverpassHome = ({ match, orgHas, userHas }) => {
    if ((userHas('spotlights') && orgHas('SPOTLIGHT')) || (userHas('campaigns') && orgHas('CAMPAIGNS'))) {
        return <Redirect to={`${match.url}/promotions`} />;

    } else if (userHas('playlists') && orgHas('MIXES')) {
        return <Redirect to={`${match.url}/playlists`} />;

    } else if (userHas('playlists') && orgHas('SMART_PLAYLISTS')) {
        return <Redirect to={`${match.url}/smart_playlists`} />;

    } else if (orgHas('SPOTLIGHT')) {
        return <Redirect to={`${match.url}/library`} />;

    } else if (orgHas('SOCIAL')) {
        return <Redirect to={`${match.url}/content`} />;
    
    } else if (userHas('audio')) {
        return <Redirect to={`${match.url}/audio`} />;
    
    } else {
        return <Redirect to={`${match.url}/no_features`} />;
    }
}

export default RedirectEverpassHome;