import {
    SET_MESSAGE,
    GET_USER_INFO,
    GET_USER_INFO_FAILURE,
    GET_USER_INFO_SUCCESS,
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_FAILURE,
    GET_USER_SETTINGS_SUCCESS,
    SET_USER_SETTINGS,
    SET_USER_SETTINGS_FAILURE,
    SET_USER_SETTINGS_SUCCESS,
    BOOKMARK_ORG,
    BOOKMARK_ORG_FAILURE,
    BOOKMARK_ORG_SUCCESS,
    CLOSE_ORG_TREE_MODAL,
    OPEN_ORG_TREE_MODAL,
    SET_CANVA_INSTANCE, CHECK_AVAILABLE_EVENT_TYPES_SUCCESS, CHECK_AVAILABLE_EVENT_TYPES_FAILURE
} from './actions';
import {REHYDRATE} from "redux-persist/constants";

const initialState = {
    token: null,
    isGettingUserInfo: false,
    gettingUserInfoError: null,
    isBookmarking: false,
    bookmarkingError: null,
    isSettingUserInfo: false,
    settingUserInfoError: null,
    isOrganizationTreeModalOpen: false,
    messages: {
        EM_ENTERTAINMENT_JUKIN_MESSAGE_CLOSED: false,
        EM_ENTERTAINMENT_JUKIN_MESSAGE_REQUESTED: false,
    },
    userInfo: {
        id: 0,
        username: '',
        email: '',
        avatar_url: 'https://avatars.upshow.tv/avatar_generator.png?string=user',
        bookmarkedOrganizations: [],
        settings: null
    },
    canvaInstance: null,
    availableEventTypes: {
        UFC: false,
        peacock: false,
        nfl_sunday_ticket: false,
        thursday_night_football: false,
        paramount: false,
        LIV: false,
        PowerSlap: false,
        FUBO: false,
        STANDARD: false,
        victory: false
    }
};

export default function (state = initialState, action) {

    const {payload, type} = action;

    switch (type) {

        case REHYDRATE: {
            if (payload.session) {
                return {
                    ...state,
                    token: payload.session.token,
                };
            }

            return state;
        }

        case CLOSE_ORG_TREE_MODAL: {
            return {
                ...state,
                isOrganizationTreeModalOpen: false,
            };
        }

        case SET_MESSAGE: {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [payload.key]: payload.value
                },
            };
        }

        case OPEN_ORG_TREE_MODAL: {
            return {
                ...state,
                isOrganizationTreeModalOpen: true, 
            };
        }

        case GET_USER_INFO: {
            return {
                ...state,
                isGettingUserInfo: true,
                gettingUserInfoError: null,
                isGettingAvailableEventTypes: true,
            };
        }

        case GET_USER_INFO_SUCCESS: {
            return {
                ...state,
                userInfo: payload.userInfo,
                isGettingUserInfo: false,
                gettingUserInfoError: null,
            };
        }

        case GET_USER_INFO_FAILURE: {
            return {
                ...state,
                isGettingUserInfo: false,
                gettingUserInfoError: payload.error,
            };
        }

        case GET_USER_SETTINGS: {
            return {
                ...state,
                isGettingUserSettings: true,
                gettingUserSettingsError: null,
            };
        }

        case GET_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    settings:payload.userSettings
                },
                isGettingUserSettings: false,
                gettingUserSettingsError: null,
            };
        }

        case GET_USER_SETTINGS_FAILURE: {
            return {
                ...state,
                isGettingUserSettings: false,
                gettingUserSettingsError: payload.error,
            };
        }

        case SET_USER_SETTINGS: {
            return {
                ...state,
                isSettingUserSettings: true,
                settingUserSettingsError: null,
            };
        }

        case SET_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                isSettingUserSettings: false,
                SettingUserSettingsError: null,
            };
        }

        case SET_USER_SETTINGS_FAILURE: {
            return {
                ...state,
                isSettingUserSettings: false,
                settingUserSettingsError: payload.error,
            };
        }

        case BOOKMARK_ORG: {
            return {
                ...state,
                isBookmarking: true,
                bookmarkingError: null,
            };
        }

        case BOOKMARK_ORG_SUCCESS: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    bookmarkedOrganizations: payload.bookmarks
                },
                isBookmarking: false,
                bookmarkingError: null,
            };
        }

        case BOOKMARK_ORG_FAILURE: {
            return {
                ...state,
                isBookmarking: false,
                bookmarkingError: payload.error,
            };
        }

        case SET_CANVA_INSTANCE: {
            return {
                ...state,
                canvaInstance: payload.canvaInstance
            }
        }

        case CHECK_AVAILABLE_EVENT_TYPES_SUCCESS: {
            return {
                ...state,
                availableEventTypes: payload.availableEventTypes,
                isGettingAvailableEventTypes: false
            };
        }

        case CHECK_AVAILABLE_EVENT_TYPES_FAILURE: {
            return {
                ...state,
                isGettingAvailableEventTypes: false,
                getAvailableEventTypesError: payload.error,
            };
        }

        default: {
            return state;
        }
    }
}