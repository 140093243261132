import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import Popover from '@mui/material/Popover';
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Button, Checkbox, Grid } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import IconDevices from '@mui/icons-material/RouterOutlined';
import RouterIcon from '@mui/icons-material/Router';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {
    MdSearch as SearchIcon,
    MdKeyboardArrowDown as CaretDownIcon,
    MdKeyboardArrowUp as CaretUpIcon,
} from "react-icons/md";
import { NavLink, withRouter } from 'react-router-dom';

import OrganizationTree from "../../components/OrganizationTree";
import setTooltipTitleByWidth from '../../helpers/setTooltipTitleByWidth';
import TopNavigationSearch from './TopNavigationSearch';
import redirectV2 from '../../views/Home/Redirect/RedirectV2';
import EverpassLogo from "../../assets/everpass.png";
import "./TopBar.scss";

class TopBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            popoverAnchorElement: null,
            rootOrganizations: null,
            showBookmarked: false,
        };

        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.handleShowBookmarked = this.handleShowBookmarked.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleUnbookmark = this.handleUnbookmark.bind(this);
        this.handleBookmark = this.handleBookmark.bind(this);
        this.filterOrgs = this.filterOrgs.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.orgNameRef = React.createRef();
        this.orgPONameRef = React.createRef();
        this.userNameRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const didChengeIsModalOpen = prevProps.isModalOpen !== this.props.isModalOpen;
        const { rootOrganizations, bookmarks } = this.props;
        if ((_isEmpty(prevProps.rootOrganizations) && !_isEmpty(rootOrganizations))
            || !_isEqual(prevProps.rootOrganizations, rootOrganizations)
            || !_isEqual(prevProps.bookmarks, bookmarks)
            || didChengeIsModalOpen || !this.state.rootOrganizations) {
            this.filterOrgs();
        }
    }

    handleShowBookmarked(event) {
        event.preventDefault();
        this.setState({
            showBookmarked: !this.state.showBookmarked,
        });
    }

    handlePopoverOpen(event) {
        event.preventDefault();

        this.setState({
            popoverOpen: true,
            popoverAnchorElement: event.currentTarget,
        });
    }


    handlePopoverClose() {
        this.setState({ popoverOpen: false });
    }

    handleOpen() {
        this.handlePopoverClose();
        this.props.openOrgTreeModal();
    }

    handleClose() {
        this.props.closeOrgTreeModal();
    }

    handleUnbookmark(org) {
        const { bookmarkOrg } = this.props;
        bookmarkOrg(org.id, false);
    }

    handleBookmark(org) {
        const { bookmarkOrg } = this.props;
        bookmarkOrg(org.id, true);
    }

    changeFilter(orgs, value) {
        return orgs.filter(org => {
            org.children = this.changeFilter(org.children, value);
            org.expanded = true;
            if (value) {
                return org.children.length > 0 || org.name.toLowerCase().includes(value.toLowerCase());
            }
            return org.children.length > 0;
        });
    }

    filterOrgs() {
        const value = this.search?.value ?? false;
        const { rootOrganizations, bookmarks } = this.props;
        const cloneRootOrganizations = _cloneDeep(rootOrganizations);
        const cloneBookmarks = _cloneDeep(bookmarks);
        if (value) {
            this.setState({
                rootOrganizations: this.changeFilter(cloneRootOrganizations, value),
                bookmarks: cloneBookmarks.filter(bookmark => bookmark.name.toLowerCase().includes(value.toLowerCase()))
            })
        } else {
            this.setState({
                rootOrganizations: cloneRootOrganizations,
                bookmarks: cloneBookmarks
            })
        }
    }



    render() {
        const { username, organizationName, avatar, baseUrl, organizationId, userHasOrgPermission, orgHasFeature, canManageDevices } = this.props;

        const { location } = this.props;
        const isDeviceManagerPageActive = location.pathname.includes('/devices');

        return (
            <div className='TopBarEverpass'>
                {!this.props.onErrorScreen &&
                    <Grid container className="content" wrap={'nowrap'}>
                        <Grid item xs='auto' className="left-content">
                            <img className="everpass-logo" src={EverpassLogo} />
                            <div className='everpass-menu'>
                                <div>
                                    <div onClick={() => redirectV2(organizationId, 'home')}>
                                        Home
                                    </div>
                                </div>
                                <div>
                                    <div onClick={() => redirectV2(organizationId, 'schedule')}>
                                        Schedule
                                    </div>
                                </div>
                                <div>
                                    <div onClick={() => redirectV2(organizationId, 'on-demand')}>
                                        On-demand
                                    </div>
                                </div>
                                <div className='active'>
                                    Marketing
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs='auto' alignSelf="center">
                            <div className="right-content">
                            <TopNavigationSearch onSearch={(value) => redirectV2(organizationId, 'search?search_term=' + encodeURI(value))}/>
                                {userHasOrgPermission('devices') && canManageDevices && orgHasFeature('DEVICES') &&
                                    <NavLink to={`${baseUrl}/devices`} className={isDeviceManagerPageActive && 'active-link'}>
                                        {isDeviceManagerPageActive ? <RouterIcon className='icon' size={24} /> : <IconDevices className='icon' size={24} />}
                                    </NavLink>
                                }
                                <div className="top-menu" onClick={this.handlePopoverOpen}>
                                    {avatar ? <img src={avatar} alt="" className="avatar" /> : <div className="avatar" />}

                                    <div className="middle">
                                        <div className="organization" ref={this.orgNameRef}
                                            onMouseEnter={() => setTooltipTitleByWidth(this.orgNameRef, organizationName)}>
                                            {organizationName}
                                        </div>
                                    </div>
                                    <div className="right">
                                        {this.state.popoverOpen ?
                                            <CaretUpIcon className='icon opened' size={18} /> :
                                            <CaretDownIcon className='icon' size={18} />}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                }
                <Popover
                    open={this.state.popoverOpen}
                    anchorEl={this.state.popoverAnchorElement}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    onClose={this.handlePopoverClose}
                    className="EverpassUserMenu"
                >
                    <div className="content">
                        <div className="top">
                            <div className="left">
                                {avatar ? <img src={avatar} alt="" className="avatar" /> : <div className="avatar" />}
                            </div>
                            <div className="middle">
                                <div className='small_text_10'>You're managing</div>
                                <div className="name-container"
                                    onMouseEnter={() => setTooltipTitleByWidth(this.orgPONameRef, organizationName)}
                                    ref={this.orgPONameRef}
                                >
                                    <h3 className="name">
                                        {organizationName}
                                    </h3>
                                    <span className='org-id'>(ID {organizationId})</span>
                                </div>
                                <div className='username' ref={this.userNameRef}
                                    onMouseEnter={() => setTooltipTitleByWidth(this.userNameRef, username)}>
                                    {username}
                                </div>
                            </div>
                            <div className="right">
                                {(userHasOrgPermission('org_settings') || userHasOrgPermission('user_management')) &&
                                    <Button className="settings-button">
                                        <NavLink to={`${baseUrl}/settings?tab=${userHasOrgPermission('org_settings') ? 'general' : 'users'}`} onClick={this.handlePopoverClose}>
                                            <SettingsIcon size={24} sx={{ strokeWidth: '0.5px' }} />
                                        </NavLink>
                                    </Button>
                                }
                                <Button onClick={this.props.logout}>
                                    <LogoutOutlinedIcon />
                                </Button>

                            </div>

                        </div>
                        <hr />
                        <div className="bottom">
                            <div className="everpassAccountModal">
                                <div className="content-account">
                                    <span className="close" onClick={this.handleClose}>CLOSE</span>
                                    <div className="search">
                                        <TextField
                                            id="search"
                                            placeholder="Search by location name"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            margin="normal"
                                            inputRef={el => this.search = el}
                                            onChange={this.filterOrgs.bind(this)}
                                            className='org-tree-search'
                                            InputProps={{
                                                classes: { input: 'search-input' },
                                                startAdornment: <InputAdornment position="start">
                                                    <SearchIcon aria-label="Search" size={20} />
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="org-tree-header">
                                        <h2>Locations</h2>
                                        <div className="checkbox-container">
                                            <Checkbox
                                                className="location-check"
                                                checked={this.state.showBookmarked}
                                                onClick={this.handleShowBookmarked}
                                            />
                                            <span>
                                                Show Only Starred
                                            </span>
                                        </div>
                                    </div>
                                    <div className="scrollableView">
                                        <OrganizationTree
                                            isLoading={this.props.isLoading}
                                            errorLoading={this.props.errorLoading}
                                            isSwitchingOrganization={this.props.isSwitchingOrganization}
                                            errorSwitchingOrganization={this.props.errorSwitchingOrganization}
                                            rootOrganizations={this.state.rootOrganizations}
                                            currentPath={this.props.currentPath}
                                            bookmarks={this.state.bookmarks}
                                            showBookmarks={this.state.showBookmarked}
                                            handleBookmark={this.handleBookmark}
                                            handleUnbookmark={this.handleUnbookmark}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popover>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={!!this.props.isModalOpen}
                    onClose={this.handleClose}
                    className="changeAccountModal"
                >
                    <div className="content">
                        <span className="close" onClick={this.handleClose}>CLOSE</span>
                        <div className="header">
                            <div className="title">Accounts</div>
                        </div>
                        <div className="search">
                            <TextField
                                id="search"
                                label="Keyword search location"
                                type="search"
                                fullWidth={true}
                                margin="normal"
                                autoFocus={true}
                                inputRef={el => this.search = el}
                                onChange={this.filterOrgs.bind(this)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <SearchIcon aria-label="Search" size={20} />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="scrollableView">
                            <OrganizationTree
                                isLoading={this.props.isLoading}
                                errorLoading={this.props.errorLoading}
                                isSwitchingOrganization={this.props.isSwitchingOrganization}
                                errorSwitchingOrganization={this.props.errorSwitchingOrganization}
                                rootOrganizations={this.state.rootOrganizations}
                                currentPath={this.props.currentPath}
                                bookmarks={this.state.bookmarks}
                                handleBookmark={this.handleBookmark}
                                handleUnbookmark={this.handleUnbookmark}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}


export default withRouter(TopBar);
