import { graphqlQuery } from '../axios';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

const spotlightDisplayRulesFragment = `
                display_rules {
                    id
                    value
                    filter
                    type
                    public_organization {
                            id
                            name
                    }
                }
                org_time_based_display_rules {
                        id
                        type
                        metadata
                        organization_id
                        public_organization {
                            id
                            name
                        }
                }
                `;

const spotlightFragment = `
    id
    title
    active
    duration
    youtube_terms_accepted
    muted
    tracking_destination
    approval_status
    background {
        id
        type
        url
        thumbnail
        small_thumbnail
        metadata
        media_object { id transcoderStatus }
        title
        organization_id
        global
        listed
        orientation
        audio
    }
    properties
    scheduling {
      id
      mondays
      tuesdays
      wednesdays
      thursdays
      fridays
      saturdays
      sundays
      validFrom
      validThrough
      validFromTime
      validThroughTime
      position
      behavior
      play_full_video
      organization: public_organization {
        id
        name
      }
      spotlight {
        title
      }
    }
    organization {
      id
      name
    }
    text: textRaw
    metadata: metadataRaw
    TrackingCode {
        id
        domain
        destination
        tracking_code
        logo
        engagement_value
        value_last_updated
    }
    spotlight_takeover_scripts(from_org_id: $org_id) {
                        id
                        name
                        Organization {
                          id
                          name
                        }
                        scheduling {
                            id
                            mondays
                            tuesdays
                            wednesdays
                            thursdays
                            fridays
                            saturdays
                            sundays
                            validFrom
                            validThrough
                            validFromTime
                            validThroughTime
                            lock
                            tz
                        }   
                    }
     ${spotlightDisplayRulesFragment}
    campaign_id
`;

export function getSpotlight (organizationId, spotlightId) {
    return graphqlQuery({
        query: `query em_get_org_spotlight($org_id: Int!, $spt_id: Int!){
        Organization(id: $org_id) {
            id
            spotlight(id: $spt_id) {
                id
                title
                active
                approval_status
                duration
                youtube_terms_accepted
                muted
                tracking_destination
                background {
                    id
                    type
                    url
                    thumbnail
                    small_thumbnail
                    media_object {transcoderStatus, durationMillis}
                    metadata
                    isTemplate
                    orientation
                    global listed
                    created_by_id
                    qr_card_layout
                    audio
                }
                text: textRaw
                TrackingCode { id destination tracking_code domain logo engagement_value value_last_updated }
                properties
                orderedScheduling(from_org_id: $org_id) {
                        id
                        mondays
                        tuesdays
                        wednesdays
                        thursdays
                        fridays
                        saturdays
                        sundays
                        validFrom
                        validThrough
                        validFromTime
                        validThroughTime
                        position
                        organization :public_organization {
                            id
                            name
                        }
                        spotlight{
                            title
                        }
                        type
                        organization_id
                        tz
                        org_spotlight_id
                        behavior
                        play_full_video
                        org_distance
                    }
                organization_id
                organization : public_organization {
                    id
                    name
                }
                metadata: metadataRaw
                tags {
                    id
                    tag
                    createdAt
                }
                spotlight_takeover_scripts(from_org_id: $org_id) {
                        id
                        name
                        Organization {
                          id
                          name
                        }
                        scheduling {
                            mondays
                            tuesdays
                            wednesdays
                            thursdays
                            fridays
                            saturdays
                            sundays
                            validFrom
                            validThrough
                            validFromTime
                            validThroughTime
                            lock
                            tz
                        }   
                    }
                campaign_id
             ${spotlightDisplayRulesFragment}
            }
        }
    }`,
        variables: { org_id: organizationId, spt_id: spotlightId }
    }).then(function (res) {
        const spt = res.data.data.Organization.spotlight;
        spt.scheduling = spt.orderedScheduling;
        return spt;
    });
}

export async function getSpotlights (organizationId) {
    const org_id = organizationId;
    const include_parents = true;
    const only_feature_enabled = true;

    const { data: axiosData } = await graphqlQuery({
        query: `query em_get_org_spotlights($org_id: Int!, $include_parents: Boolean, $only_feature_enabled: Boolean){
            Organization(id: $org_id) {
                id 
                spotlights(includeParents: $include_parents, applyFeatures: $only_feature_enabled) {
                    id
                    __typename
                    title
                    active
                    duration
                    approval_status
                    youtube_terms_accepted
                    muted
                    tracking_destination
                    organization_id
                    organization {
                      id
                      name
                    }
                    background {
                        id
                        type
                        url
                        thumbnail
                        small_thumbnail
                        metadata
                        orientation
                        global
                        listed
                        created_by_id
                        audio
                    }
                    no_content
                    properties
                    scheduling(from_org_id: $org_id) {
                        id
                        mondays
                        tuesdays
                        wednesdays
                        thursdays
                        fridays
                        saturdays
                        sundays
                        validFrom
                        validThrough
                        validFromTime
                        validThroughTime
                        position
                        organization {
                          id
                          name
                        }
                        spotlight{
                            title
                            id
                            active
                        }
                        type
                        tz
                        organization_id
                        org_spotlight_id
                        behavior
                        play_full_video
                    }
                    text: textRaw
                    TrackingCode { id destination tracking_code domain logo engagement_value value_last_updated }
                    metadata: metadataRaw
                    tags {
                        id
                        tag
                        createdAt
                    }
                    spotlight_takeover_scripts(from_org_id: $org_id) {
                        id
                        name
                        Organization {
                          id
                          name
                        }
                        scheduling {
                            mondays
                            tuesdays
                            wednesdays
                            thursdays
                            fridays
                            saturdays
                            sundays
                            validFrom
                            validThrough
                            validFromTime
                            validThroughTime
                            lock
                            tz
                        }   
                    }
                    campaign_id
                    ${spotlightDisplayRulesFragment}
                }
            }
        }`, variables: { org_id, include_parents, only_feature_enabled }
    });
    const sortedSpotlights = _orderBy(axiosData.data.Organization.spotlights, ['id'], ['desc']);

    return sortedSpotlights;
}

export function createDisplayRulesInSpotlight (id, org_id, labelDisplayRules, dateRangeDisplayRule, weekDaysDisplayRule, timeRangeDisplayRule) {
    return graphqlQuery({
        query: `mutation createDisplayRulesInSpotlight($org_id: Int!, $id: Int!, $labelDisplayRules: [DisplayRuleInput!], $dateRangeDisplayRule: InputMetadataDateRangeDisplayRule, $weekDaysDisplayRule: InputMetadataWeekDaysDisplayRule,  $timeRangeDisplayRule: InputMetadataTimeRangeDisplayRule){
            orgMutation(organization_id: $org_id){
                manageTimeBasedDisplayRules {
                    upsertDisplayRule(object_id: $id object_type: spotlight display_rules: $labelDisplayRules){
                      successful
                    }
                    upsertDateRangeDisplayRule(object_id: $id object_type: spotlight metadata: $dateRangeDisplayRule) @include(if: ${!!dateRangeDisplayRule}) {
                      successful
                    }
                    upsertWeekDaysDisplayRule(object_id: $id object_type: spotlight metadata: $weekDaysDisplayRule) @include(if: ${!!weekDaysDisplayRule}){
                      successful
                    }
                    upsertTimeRangeDisplayRule(object_id: $id object_type: spotlight metadata: $timeRangeDisplayRule) @include(if: ${!!timeRangeDisplayRule}){
                      successful
                    }
                }
            }
        }`, variables: { id, org_id, labelDisplayRules, dateRangeDisplayRule, weekDaysDisplayRule, timeRangeDisplayRule }
    });
}

export function createSpotlight (org_id, spotlight, addSchedules, displayRules, addTags, scriptSchedules) {
    const { weekDaysDisplayRule, dateRangeDisplayRule, timeRangeDisplayRule, labelDisplayRules = [] } = displayRules;

    return graphqlQuery({
        query: `mutation createSpotlight($org_id: Int! $spotlight: SpotlightInput! $addSchedules: [OrgScheduleInput!], $addTags: [String!], $scriptSchedules: [ScriptScheduleInput!]){
            orgMutation(organization_id: $org_id){
                createSpotlight(input: $spotlight){
                    status
                    successful
                    message
                    spotlight {
                        ${spotlightFragment}
                    }
                    
                    addSchedules(schedules: $addSchedules){ 
                        successful
                    }

                    addTags(tags: $addTags){
                        successful
                    }
                     create_script_takeover(scriptSchedules: $scriptSchedules, org_id: $org_id){
                        successful
                    }
                }
            }
        }`, variables: { org_id, spotlight, addSchedules, labelDisplayRules, addTags, dateRangeDisplayRule, weekDaysDisplayRule, timeRangeDisplayRule, scriptSchedules }
    }).then(async response => {
        const createSpotlight = _get(response, 'data.data.orgMutation.createSpotlight', {});
        await createDisplayRulesInSpotlight(createSpotlight.spotlight.id, org_id, labelDisplayRules, dateRangeDisplayRule, weekDaysDisplayRule, timeRangeDisplayRule);
        return createSpotlight;
    });
}

export function updateSpotlight (org_id, id, spotlight, unsafe_update, addSchedules, deleteSchedules, displayRules, addTags, deleteTags, scriptSchedules, delete_script_ids) {
    const { weekDaysDisplayRule, dateRangeDisplayRule, timeRangeDisplayRule, labelDisplayRules = [], removedDisplayRules = [], removedTimeBasedDRs = []} = displayRules;

    // We need Label Display Rules public_organization in SpotlightContext to show the creator but we need to remove it before mutation.
    const newLabelDisplayRules = labelDisplayRules.map(({ id, type, value, filter }) => { return { id, type, value, filter }});

    return graphqlQuery({
        query: `mutation updateSpotlight($org_id: Int!, $id: Int!, $spotlight: SpotlightInput!, $unsafe_update: Boolean,
                                        $addSchedules: [OrgScheduleInput!], $deleteSchedules: [Int!], 
                                        $labelDisplayRules: [DisplayRuleInput!], $dateRangeDisplayRule: InputMetadataDateRangeDisplayRule, $weekDaysDisplayRule: InputMetadataWeekDaysDisplayRule,  $timeRangeDisplayRule: InputMetadataTimeRangeDisplayRule, 
                                        $removedDisplayRules: [Int!], $removedTimeBasedDRs: [Int!],
                                        $addTags: [String!], $deleteTags: [Int!], $scriptSchedules: [ScriptScheduleInput!], $delete_script_ids: [Int!]){
            orgMutation(organization_id: $org_id){
                updateSpotlight(id: $id, input: $spotlight, unsafe_update: $unsafe_update){
                    status
                    successful
                    message
                    spotlight {
                        ${spotlightFragment}
                    }
                    addSchedules(schedules: $addSchedules) { 
                        successful
                    }
                    deleteSchedules(schedule_ids: $deleteSchedules){ 
                        successful
                    }
                    addTags(tags: $addTags){
                        successful
                    }
                    deleteTags(tags_ids: $deleteTags){
                        successful
                    }
                    create_script_takeover(scriptSchedules: $scriptSchedules, org_id: $org_id){
                        successful
                    }
                    delete_script_takeover(delete_script_ids: $delete_script_ids){
                        successful
                    }
                }
                manageTimeBasedDisplayRules {
                    upsertDisplayRule(object_id: $id object_type: spotlight display_rules: $labelDisplayRules) {
                        successful
                    }
                    upsertDateRangeDisplayRule(object_id: $id object_type: spotlight metadata: $dateRangeDisplayRule) @include(if: ${!!dateRangeDisplayRule}) {
                      successful
                    }
                    upsertWeekDaysDisplayRule(object_id: $id object_type: spotlight metadata: $weekDaysDisplayRule) @include(if: ${!!weekDaysDisplayRule}){
                      successful
                    }
                    upsertTimeRangeDisplayRule(object_id: $id object_type: spotlight metadata: $timeRangeDisplayRule) @include(if: ${!!timeRangeDisplayRule}){
                      successful
                    }
                    deleteTimeBasedDisplayRules(time_based_display_rules_ids: $removedTimeBasedDRs){
                      successful
                    }
                    deleteDisplayRules(display_rules_ids: $removedDisplayRules){
                      successful
                    }
                 }
            }
        }`, variables: {
            org_id, id, spotlight, unsafe_update, addSchedules, deleteSchedules,
            labelDisplayRules: newLabelDisplayRules, removedDisplayRules, removedTimeBasedDRs, addTags, deleteTags,
            dateRangeDisplayRule, weekDaysDisplayRule, timeRangeDisplayRule, scriptSchedules, delete_script_ids
        }
    }).then(response => _get(response, 'data.data.orgMutation.updateSpotlight', {}));
}

export function updateParentSpotlight (org_id, id, addSchedules, deleteSchedules, scriptSchedules, delete_script_ids) {
    return graphqlQuery({
        query: `mutation updateParentSpotlight($org_id: Int!, $id: Int!, $addSchedules: [OrgScheduleInput!], $deleteSchedules: [Int!], 
        $scriptSchedules: [ScriptScheduleInput!], $delete_script_ids: [Int!]){
            orgMutation(organization_id: $org_id){
                updateParentSpotlight(id: $id){
                    status
                    successful
                    message
                    spotlight {
                        ${spotlightFragment}
                    }
                    addSchedules(schedules: $addSchedules) { 
                        successful
                    }
                    deleteSchedules(schedule_ids: $deleteSchedules){ 
                        successful
                    }
                    create_script_takeover(scriptSchedules: $scriptSchedules, org_id: $org_id){
                        successful
                    }
                    delete_script_takeover(delete_script_ids: $delete_script_ids){
                        successful
                    }
                }
            }
        }`, variables: { org_id, id, addSchedules, deleteSchedules, scriptSchedules, delete_script_ids }
    }).then(response => _get(response, 'data.data.orgMutation.updateParentSpotlight', {}));
}

export function deleteSpotlight (organizationId, spotlightId) {
    return graphqlQuery({
        query: `mutation deleteSpotlight($organizationId: Int!, $spotlightId: Int!) {
            orgMutation(organization_id: $organizationId) {
                deleteSpotlight(id: $spotlightId) {
                    status
                    message
                    successful
                }
            }
        }`, variables: { organizationId, spotlightId }
    }).then(response => _get(response, 'data.data.orgMutation.deleteSpotlight', {}));
}

export function disableSpotlights (spotlightIds) {
    return graphqlQuery({
        query: `mutation disable_spotlights($spotlightIds: [Int!]!){
            disable_spotlights(ids: $spotlightIds)
        }`, variables: { spotlightIds }
    })
        .then(response => _get(response, 'data.data.disable_spotlights', false));
}

export function deleteSpotlights (spotlightIds) {
    return graphqlQuery({
        query: `mutation delete_spotlights($spotlightIds: [Int!]!){
            delete_spotlights(ids: $spotlightIds)
        }`, variables: { spotlightIds }
    })
        .then(response => _get(response, 'data.data.delete_spotlights', false));
}
