import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import EmptyState from '../../../components/EmptyState';
import redirectV2 from './RedirectV2';

const GET_ORGANIZATIONS_IDS = gql`query getOrganizationsIds { me { organizations { id } } }`;

const RedirectEntertainment = () => {
    const { loading, data, error } = useQuery(GET_ORGANIZATIONS_IDS);
    const orgs = data?.me.organizations.map(o => o.id) || [];

    if (loading) return <div className="Organizations"><LoadingOverlay/></div>;

    if (error || !orgs[0]) return <EmptyState type="ERROR"/>;

    redirectV2(orgs[0], 'home');
    // Never reaches this return
    return <Redirect to={`/v2/${orgs[0]}/home`}/>;
};

export default RedirectEntertainment;