import { gql } from '@apollo/client';

const GET_ORG_SETTING = gql`
    query manager_get_org_setting ($organizationId: Int!) {
        Organization (id: $organizationId) {
            id
            settings {
                key
                value
                organization { id }
                inherited
            }
            parent_organization {
                id
                settings{
                    key
                    value
                    organization { id }
                    inherited
                }
            }
        }
    }
`;

const SET_ORG_SETTING = gql`
    mutation setOrgSetting($organizationId: Int! $key: String! $value: JSON, $notify_screens: Boolean) {
        setSetting(org_id: $organizationId key: $key value: $value, notify_screens: $notify_screens)
    }
`;

const SET_ORG_SETTINGS = gql`
    mutation setSettings ($organizationId: Int!, $settings: [JSON], $salesforceSettings: [JSON]) {
        setSettings (
            org_id: $organizationId,
            settings: $settings,
            salesforceSettings: $salesforceSettings
        )
    }
`;

const GET_ORG_SETTINGS = gql`
    query getOrgSettings($organizationId: Int!, $includeParents: Boolean = true, $key: String, $fontPrefix: String) {
      Organization(id: $organizationId) {
        id
        fontSettings: settings(includeParents: $includeParents, key: $key, prefix: $fontPrefix) {
          id: key
          key
          value
          organization {
            id
          }
          inherited
        }
      }
    }
`;

//TODO: Do not use SalesforceMetadata
const GET_SALESFORCE_METADATA = gql`query getSalesforceData($organizationId: Int!) {
    Organization(id: $organizationId) {
        id
        SalesforceMetadata {
            id
            metadata
        }
    }
}`;

const GET_SETTINGS_WITH_CAMPAIGNS = gql`
    query get_settings_with_campaigns ($organizationId: Int!) {
        Organization (id: $organizationId) {
            id
            settings {
                id: key
                key
                value
                organization { id }
                inherited
            }
            parent_organization {
                id
                settings{
                    key
                    value
                    organization { id }
                    inherited
                }
            }
            campaigns(include_parents: true include_archived: false) {
                id
                name
                type
                created_at
                organization_id
                is_single_url
                metadata {
                    usages
                    campaignHub {
                        icon
                        cta
                    }
                    fixedPanel {
                        cta
                    }
                }
                TrackingCode {
                    id
                    domain
                    track_url
                    tracking_code
                    engagement_value
                    destination
                    metadata {
                        androidUrl iosUrl
                    }
                    tracking_destination(organization_id: $organizationId) {
                        destination
                    }
                }
            }
        }
    }
`;

const GET_ORG_ZIPCODE = gql`query getOrgZipCode($org_id: Int!) {
    Organization(id: $org_id) {
        id
        zipcode: settings(key: "zip_code", includeParents: false) { value }
    }
}`;

const SET_ORG_ZIPCODE = gql`
    mutation setOrgZipcode($org_id: Int! $zipcode: JSON) {
        setSetting(key: "zip_code" value: $zipcode org_id: $org_id)
    }`;

const GET_ORGANIZATION_USERS = gql`
    query getOrganizationUsers($org_id: Int!) {
        Organization(id: $org_id) {
            id
            allowedDomains: settings(key: "allowedDomains", includeParents: true) { value }
            users(includeParents: true) {
                id
                email
                okta_status_um
                has_access_to_organization(id: $org_id direct_access: true)
                UserOrgs(org_id: $org_id, includeParents: true) {
                    organization { id }
                    groups {
                        id
                        display_name
                    }
                }
                organizations (related_to: $org_id) {
                    id
                    name
                    child_tree
                }
            }
        }
    }`

export {
    GET_ORG_SETTING,
    GET_ORG_SETTINGS,
    SET_ORG_SETTING,
    SET_ORG_SETTINGS,
    GET_SALESFORCE_METADATA,
    GET_SETTINGS_WITH_CAMPAIGNS,
    GET_ORG_ZIPCODE,
    SET_ORG_ZIPCODE,
    GET_ORGANIZATION_USERS
};
