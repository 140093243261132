import { DEFAULT_LOCATION, detectQRPlaceholder } from '@upshow/image-analyzer';
import { getSpotlightAsset } from '../redux/modules/entities/spotlightAssets/actions';
import { getBufferFromBase64Image, getVideoThumbnail } from './image';
import { getCustomFontURL, isCustomFont } from './customFonts';

export const DEFAULT_UPCODE_DURATION = 45;

export const DEFAULT_BACKGROUNDS = {
    video: {
        vertical: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_VIDEO_V, 10),
        horizontal: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_VIDEO_H, 10)
    },
    image: {
        vertical: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_IMAGE_V, 10),
        horizontal: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_IMAGE_H, 10)
    },
    qr: {
        vertical: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_IMAGE_V, 10),
        horizontal: parseInt(process.env.REACT_APP_DEFAULT_SPOT_BG_IMAGE_QR_H, 10)
    }
};

export function getDefaultBackgrounds () {
    return Object.values(DEFAULT_BACKGROUNDS).map(b => Object.values(b)).flat();
}

export function isDefaultBackground (id) {
    return getDefaultBackgrounds().includes(id);
}

export function getDefaultMetadataConfiguration (brandkit) {
    if (!brandkit) return;

    const custom_fonts = [];

    if (isCustomFont(brandkit['font_title_family'])) custom_fonts.push({ name: brandkit['font_title_family'], url: getCustomFontURL(brandkit['font_title_family'])});
    if (isCustomFont(brandkit['font_body_family'])) custom_fonts.push({ name: brandkit['font_body_family'], url: getCustomFontURL(brandkit['font_body_family'])});

    return {
        custom_fonts
    };
}

export function getDefaultTextConfiguration () {
    return {
        title: '',
        body: '',
        message_board: '',
        title_font_size: 'medium',
        body_font_size: 'medium',
        position: 'top center',
    };
}

export function getBackgroundImage (spotlight) {
    if (!!spotlight.background && !!spotlight.background.thumbnail) {
        return spotlight.background.thumbnail;
    } else return null;
}

export function normalizeSpotlight (spotlight) {
    if (spotlight) {
        return {
            ...spotlight,
            img: getBackgroundImage(spotlight),
            author: spotlight.reel_name
        };
    }
}

export function spotlightTemplate (orgId, defaultBackgrounds, {
    type,
    isVertical = false,
    background = null,
    brandkit = {},
    useMetadataBackground = false
}) {
    const text = getDefaultTextConfiguration();
    const metadata = getDefaultMetadataConfiguration(brandkit);

    const properties = isVertical ? ['clockwise_rotated'] : ['media', 'fullscreen'];

    const orientation = isVertical ? 'vertical' : 'horizontal';

    let background_id;
    let shouldGetDefaultBackground = false;

    if (background) background_id = background.id;

    switch (type) {
        case 'image':
            background_id = DEFAULT_BACKGROUNDS.image[orientation];
            shouldGetDefaultBackground = !background;
            break;
        case 'video':
            background_id = DEFAULT_BACKGROUNDS.video[orientation];
            shouldGetDefaultBackground = !background;
            break;
        case 'upcode':
            if (!useMetadataBackground) {
                background_id = DEFAULT_BACKGROUNDS.qr[orientation];
                shouldGetDefaultBackground = !background;
            }
            if (!isVertical) {
                properties.push('qr');
                properties.push('qr-theme-light');
            }
            break;
        case 'layout':
            properties.push('layout_type_sidebar');
            break;
    }

    if (!background && (shouldGetDefaultBackground || !brandkit.background_color)) {
        background = defaultBackgrounds.find(background => background.id === background_id);
    }

    const spotlight = {
        background_id,
        text,
        title: undefined,
        active: false,
        background,
        tracking_destination: '',
        duration: type === 'upcode' ? DEFAULT_UPCODE_DURATION : 15,
        organization: orgId,
        position: null,
        youtube_terms_accepted: false,
        properties,
        metadata,
    };

    if (type === 'canva') {
        spotlight.background = {
            id: background.id,
            type: background.type,
            url: background.url,
            media_object: background.media_object,
            metadata: background.metadata,
            orientation: [orientation]
        };
    }

    return spotlight;
}

export function getSpotlightAssetByType (dispatch, organizationId, type = 'all') {
    const backgroundIds = [];

    if (type === 'image' || type === 'all') {
        backgroundIds.push(DEFAULT_BACKGROUNDS.image.vertical);
        backgroundIds.push(DEFAULT_BACKGROUNDS.image.horizontal);
    }

    if (type === 'video' || type === 'all') {
        backgroundIds.push(DEFAULT_BACKGROUNDS.video.vertical);
        backgroundIds.push(DEFAULT_BACKGROUNDS.video.horizontal);
    }

    if (type === 'qr' || type === 'all') {
        backgroundIds.push(DEFAULT_BACKGROUNDS.qr.vertical);
        backgroundIds.push(DEFAULT_BACKGROUNDS.qr.horizontal);
    }

    for (const backgroundId of backgroundIds) {
        dispatch(getSpotlightAsset(organizationId, backgroundId));
    }
}

export function getValidDestinationURL (url) {
    if (!url) return url;
    try {
        new URL(url);
    } catch (_) {
        url = 'https://' + url;
    }
    return url.trim();
}

const RECOMMENDED_CAMPAIGN_VIDEO_DURATION = 45000;
const RECOMMENDED_ANNOUNCEMENT_VIDEO_DURATION = 15000;

export function getRecommendedVideoDuration (media_object, campaign_id) {
    if (!!campaign_id && media_object.durationMillis < RECOMMENDED_CAMPAIGN_VIDEO_DURATION) return RECOMMENDED_CAMPAIGN_VIDEO_DURATION / 1000;
    if (!campaign_id && media_object.durationMillis < RECOMMENDED_ANNOUNCEMENT_VIDEO_DURATION) return RECOMMENDED_ANNOUNCEMENT_VIDEO_DURATION / 1000;
}

export async function getQRCardPosition (exportUrl) {
    try {
        const thumbnail = await getVideoThumbnail(exportUrl);
        const buffer = getBufferFromBase64Image(thumbnail);
        const {
            position,
            orientation: qrOrientation,
            error: detectionError
        } = await detectQRPlaceholder(buffer, 'buffer');
        if (!detectionError) {
            return { position, orientation: qrOrientation };
        }
    } catch (e) {
        console.error(e);
    }

    return { position: DEFAULT_LOCATION, orientation: 'vertical' };
}

export default normalizeSpotlight;
